import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../containers/layout"

const NotFoundPage = () => (
  <>
    <Helmet>
      <link href="/css/bootstrap.min.css" rel="stylesheet" type="text/css" />
    </Helmet>
    <Layout>
      <section className="bg-half-170 border-bottom d-table w-100 text-center">
        <h1>Signal 404</h1>
        <p>Spaceship has crashed... we're lost... help</p>
      </section>
    </Layout>
  </>
)

export default NotFoundPage
